@charset "UTF-8";
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
.row > .breadcrumb {
  flex-grow: 1;
}

body.home .breadcrumb {
  display: none;
}

.field-switch-breadcrumb {
  display: none;
}

.breadcrumb-bar {
  border-left: 1px solid #eaebeb;
  border-right: 1px solid #eaebeb;
  background: #fff;
}

.breadcrumb-bar .column-splitter {
  margin: 0;
}

@media (max-width: 767px) {
  .breadcrumb-bar {
    display: none;
  }
}

article .breadcrumb-bar {
  background: #f7f7f7;
  margin-top: 2.4em;
  border-top: 0;
}

.breadcrumb {
  background: transparent;
  overflow: hidden;
}

.breadcrumb.component {
  margin-top: 0;
  padding: 1.1rem 3.1rem;
}

@media (max-width: 767px) {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb.breadcrumb-navigation {
  overflow: visible;
}

.breadcrumb a {
  font-size: 1.4em;
  font-weight: 400;
  text-decoration: none;
}

.breadcrumb ol {
  list-style: none;
}

.breadcrumb ol:after {
  content: '';
  display: table;
  clear: both;
}

.breadcrumb .field-title {
  display: inline-block;
}

.breadcrumb .separator {
  display: none;
}

.breadcrumb li {
  display: block;
  position: relative;
  padding: 0 11.5px;
  float: left;
}

.breadcrumb li > .navigation-title {
  display: inline-block;
  vertical-align: middle;
}

.breadcrumb li > .navigation-title > a:hover {
  color: #707070;
  text-decoration: underline;
}

.breadcrumb li.home {
  padding-left: 0;
}

.breadcrumb li.last {
  padding-right: 0;
}

.breadcrumb li:hover > ol {
  display: block;
  position: absolute;
  top: 20px;
  left: auto;
  z-index: 100;
}

.breadcrumb li > ol {
  display: none;
  margin: 0;
  background: #FFFFFF;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
}

.breadcrumb li > ol li {
  display: inline-block;
  border-bottom: 1px solid #d2d2d2;
  position: relative;
  width: 100%;
  min-width: 140px;
  margin: 0;
  padding: 2px 20px;
  float: none;
  cursor: pointer;
  vertical-align: middle;
  box-sizing: border-box;
}

.breadcrumb li > ol li .fa {
  float: left;
}

.breadcrumb li > ol li:hover {
  background: #89C6CC;
  color: #fff;
}

.breadcrumb li > ol li:hover a {
  color: #fff !important;
}

.breadcrumb li > ol li:before {
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-size: 1.4rem;
  content: "";
}

.breadcrumb li > ol li > a {
  display: block;
  padding: 2px 0 2px 10px;
}

.breadcrumb .grey-background {
  background: #f7f7f7;
  border-bottom: #eaebeb;
}

.social-links {
  padding-right: 1.3em;
}

.social-links .row {
  flex-wrap: nowrap;
  align-items: center;
  width: 16rem;
  justify-content: flex-end;
}

.social-links .col-3 {
  display: flex;
  align-content: center;
}

.social-links a {
  color: #bbbcbc;
  font-size: 1.875em;
  padding: 0.4em 0;
}

.social-links a:hover, .social-links a:focus {
  color: #55565b;
}

.social-links a i {
  padding: 0 9px;
}

/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.breadcrumb.breadcrumb-hide li {
  white-space: nowrap;
}

.breadcrumb.breadcrumb-hide li.item-hide + li.item-hide {
  display: none;
}

.breadcrumb.breadcrumb-hide li.item-hide a {
  display: none;
}

.breadcrumb.breadcrumb-hide li.item-hide:before {
  content: "...";
  display: inline;
}

.breadcrumb.breadcrumb-hide li.hide-full {
  display: none;
}

/**
 * Grid Variables - CH
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.breadcrumb.triangle-separator .separator {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0;
}

.breadcrumb.triangle-separator .separator:before {
  content: "";
  display: block;
  font-size: 16px;
}
